import logo from './logo.svg';
import './App.css';

import Home from './pages/home';

import About from './pages/about';

import Services from './pages/services';

import Contact from './pages/contact';
// import video_preview_h264 from '';


function App() {
  return (
    <div className="App">
      
      <header className="App-header">
      <video muted autoPlay loop >
      <source src='./tech-fly-through-03-2022-08-04-14-13-53-utc.mp4' type="video/mp4" />

        </video>

        <h1 className='WelcomeText'>Accelerate Club</h1>
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
      </header>
      <Home />
      <About />
      <Services />
      <Contact />


    </div>
  );
}

export default App;
