const About = () => {

    return (

        <div>

            <h1>About Us</h1>

            <p>

                AccelerateClub is a consulting and outsourcing company that specializes

                in cutting edge technologies and execution of hard technological

                problems, products and projects. We are a team of experienced

                professionals with a passion for technology and a desire to help

                others achieve their goals.

            </p>

        </div>

    );

};


export default About;