import React from 'react';


const Contact = () => {

    return (

        <div>

            <h1>Contact Us</h1>
            <iframe src="https://docs.google.com/forms/d/1ivkcLeJ0yhXt-6LS0eQDXnq4KsvW-nXr0E5hKNlUhmQ/viewform?embedded=true" width="640" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>

    );

};

export default Contact;
