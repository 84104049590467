import React from 'react';


const Home = () => {

    return (

        <div>

            <h1>Welcome to Accelerate Club</h1>

            <p>

                Accelerate Club is the go-to source for cutting edge technologies and

                problem solving.

            </p>

        </div>

    );

};


export default Home;

