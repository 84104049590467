

const Services = () => {

    return (

        <div>

            <h1>Our Services</h1>

            <p>

                AccelerateClub offers a wide range of services to help you achieve your

                goals. Our services include:

            </p>

            <ul>


                <li>
                    <img className="icon"
                    src="images/engineering.png" alt="Engineering" />Engineering</li>

                <li>
                    <img className="icon" src="images/ai.png" alt="ai" />
                    AI</li>

                <li>
                    <img className="icon" src="images/blockchain.png" alt="blockchain" />
                    Blockchain</li>

                <li>
                    <img className="icon" src="images/finance.png" alt="finance" />
                    Finance</li>

                <li>
                    <img className="icon" src="images/bio.png" alt="bio" />
                    Biotechnology</li>

                <li>
                    <img className="icon" src="images/operations.png" alt="ops" />
                    Operations</li>

                <li>
                    <img className="icon" src="images/problem-solving-2.png" alt="software" />
                    Software</li>

                <li>
                    <img className="icon" src="images/problem-solving.png" alt="problem solving" />
                    General Problem Solving</li>

            </ul>

        </div>

    );

};


export default Services;